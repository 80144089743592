import { RasaContext } from 'context'
import * as Flash from 'components/flash'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import './styles.scss'
import './stripo.scss'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Loading } from 'components/loading'
import { DATE_FORMATS, EmailAttributes, getAttribute, getDateFormatOptions } from './constants'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { getAllImageLocationsOptions } from 'shared_server_client/utils'

type StripoState = {
  configs: any,
  communityId: string,
  emailLayoutId: string,
  emailLayout: any,
  isDirty: boolean,
  isLoading: boolean,
  isSaving: boolean,
}

type StripoProps = GenericRedux.AllComponentProps<any>

// Hack to ensure we can type-safe access window.Stripo
declare const window: any;
declare const STRIPO_PLUGIN_ID: string;
declare const STRIPO_SECRET_KEY: string;
declare const RASA_ASSETS_CDN: string
const stripoRequest = (method, url, data, callback) => {
  const req = new XMLHttpRequest();
  req.onreadystatechange = function () {
    if (req.readyState === 4 && req.status === 200) {
      callback(req.responseText);
    } else if (req.readyState === 4 && req.status !== 200) {
      // eslint-disable-next-line no-console
      console.error('Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values');
    }
  };
  req.open(method, url, true);
  if (method !== 'GET') {
    req.setRequestHeader('content-type', 'application/json');
  }
  req.send(data);
}

export class StripoEditorComponent extends RasaReactComponent<StripoProps, StripoState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props, 'stripoHtmlTemplates')
    this.state = {
      communityId: "",
      configs: {},
      emailLayout: {},
      emailLayoutId: "",
      isDirty: false,
      isLoading: false,
      isSaving: false,
    }
    this.getAndSaveTemplate = this.getAndSaveTemplate.bind(this)
    this.getRasaImageURL = this.getRasaImageURL.bind(this)
    this.setRasaConfigValue = this.setRasaConfigValue.bind(this)
    this.saveConfigs = this.saveConfigs.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.setState({
        communityId: activeCommunity.communityId,
        emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
      }, () => {
        this.loadTemplates(this.state.communityId)
      })
    })
  }


  public render() {
    return <div className="stripo">
      <div id="externalSystemContainer">
        {this.state.isSaving ? <Loading size="32" />:
        <div>
          <button id="saveButton" onClick={this.saveTemplate} className="control-button">Save</button>
        </div>}
      </div>
      <div className="notification-zone"></div>
      <div>
        <div id="stripoSettingsContainer"><Loading size="64" /></div>
        <div id="stripoPreviewContainer"></div>
      </div>
    </div>
  }

  public getRasaImageURL = (imageUrl) => {
    const imageValidateEndpoint: string =  `${AjaxWrapper.getServerUrl()}/${this.state.communityId}/gallery/source`
    return AjaxWrapper.ajax(imageValidateEndpoint, HttpMethod.POST, {imageUrl})
  }

  public setRasaConfigValue = (key, value) => {
    this.setState({
      configs: {
        ...this.state.configs,
        [key]: value
      }
    })
  }

  private saveConfigs = () => {
    this.context.entityMetadata.getEntityObject('email_layout', this.state.communityId, this.state.emailLayoutId)
      .then((emailLayoutEntityObject: BaseClientEntity) => {
        Object.keys(this.state.configs).forEach((key) => {
          const value = this.state.configs[key]
          emailLayoutEntityObject.data[key] = value
        })
        return emailLayoutEntityObject.save()
      })
      .catch()
  }

  private getEmailLayoutInfo = () => {
    return this.context.entityMetadata.getEntityObject('email_layout').then(
      (entityObject: any ) => {
      return new Promise ((resolve, reject) => {
        entityObject.load(this.state.communityId, this.state.emailLayoutId).then(() => {
        this.setState({
          emailLayout: {
            ...entityObject.data,
          }
        }, () => {
          resolve(entityObject.data)
        })
      })
      })
    })
  }

  private loadTemplates = (communityId) => {
    this.getEmailLayoutInfo().then(() => {
      const url: string = AjaxWrapper.getServerUrl() + `/stripo/templates/${communityId}`
      return AjaxWrapper.ajax(url, HttpMethod.GET, {}).then((template) => {
        return getAllImageLocationsOptions().then((imageLocationOptions) => {
          this.initPlugin(template, this.state.emailLayout, this.getRasaImageURL, this.setRasaConfigValue, imageLocationOptions)
        })
      })
    })
  }

  private saveTemplate = () => {
    this.setState({
      isSaving: true
    })
    window.StripoApi.getTemplate(this.getAndSaveTemplate)
    this.saveConfigs()
  }

  private getAndSaveTemplate = (html, css) => {
    //your save logic should be here
    const url: string = AjaxWrapper.getServerUrl() + `/stripo/templates/${this.state.communityId}`
    return AjaxWrapper.ajax(url, HttpMethod.POST, {
      html,
      css
    }).then((template) => {
      this.setState({
        isSaving: false,
      })
      this.context.store.dispatch(Flash.showFlashMessage('Template saved successfully.'))
    }).catch((error) => {
      this.setState({
        isSaving: false,
      })
      this.context.store.dispatch(Flash.showFlashMessage('Failed to save template. Please try again.'))
    })
  }

  private initPlugin(template, emailLayout, getRasaImageUrl, setRasaConfigValue, imageLocationOptions) {
    const dateFormats = DATE_FORMATS
    if (!dateFormats.includes(getAttribute(emailLayout, EmailAttributes.headerDateFormat))) {
      dateFormats.push(getAttribute(emailLayout, EmailAttributes.headerDateFormat))
    }
    const stripoPluginId = 'a505a4d95a464a099c3286e06ed21428'
    const stripoSecretKey = 'ae890c57131645428405fb891e0aa661'
    const apiRequestData = {
      emailId: 123,
      accountId: "000000",
    };
    const script = document.createElement('script');
    script.id = 'stripoScript';
    script.type = 'text/javascript';
    script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
    script.onload = function () {
      if (!window.Stripo ) {
        return
      }
      // eslint-disable-next-line no-undef
      window.Stripo.init({
        settingsId: 'stripoSettingsContainer',
        previewId: 'stripoPreviewContainer',
        codeEditorButtonId: 'codeEditor ',
        undoButtonId: 'undoButton',
        redoButtonId: 'redoButton',
        locale: 'en',
        html: template.html,
        css: template.css,
        apiRequestData,
        userFullName: 'Plugin Demo User',
        versionHistory: {
          changeHistoryLinkId: 'changeHistoryLink',
        },
        "extensions": [
          {
            "globalName": "HeaderExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "BannerExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }, {
            "globalName": "ArticleExtension",
            "url": `${RASA_ASSETS_CDN}/stripo/rasa-stripo-extensions.js`,
          }
       ],
       "rasaCallbacks": {
        getRasaImageUrl,
        setRasaConfigValue
      },
       "headerBlock": {
          "enabled": true,
          "brand_header_image_url": getAttribute(emailLayout, EmailAttributes.brandHeaderImageUrl),
          "brand_header_alt_text": getAttribute(emailLayout, EmailAttributes.brandHeaderAltText),
          "dateFormatOptions": getDateFormatOptions(dateFormats),
          "header_date_format": getAttribute(emailLayout, EmailAttributes.headerDateFormat),
          "show_subscribe_url_in_header": getAttribute(emailLayout, EmailAttributes.showSubscribeUrlInHeader),
          "show_unsubscribe_url_in_header": getAttribute(emailLayout, EmailAttributes.showUnsubscribeUrlInHeader),
       },
       "bannerBlock": {
          "enabled": true,
          "locationOptions": imageLocationOptions,
        },
        "articleBlock": {
          "enabled": true,
        },
        getAuthToken: (callback) => {
          stripoRequest('POST', 'https://plugins.stripo.email/api/v1/auth',
            JSON.stringify({
              pluginId: stripoPluginId,
              secretKey: stripoSecretKey,
            }),
            function(data) {
              callback(JSON.parse(data).token);
            }
          );
        }
      });
    };
    document.body.appendChild(script);
  }

}

export const StripoEditor = GenericRedux.registerNewComponent<any>(
  StripoEditorComponent,
  'stripo',
  {})
